@media (min-width: 1400px) {
  .outerGutters {
    max-width: 1200px;
  }

  .footerWrapper {
    max-width: 1400px;
    margin: 0 auto;
  }
} ;
