:root {
  --lazerLemon: #ffff6d;
  --corn: #ddaa00;
  --paleCorn: #ddaa0080;
}

.italicWrapper {
  background-image: url(../assets/stairs2crop.jpg);

  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  max-width: 350px;
  border-top: 5px solid var(--corn);
  /* border-radius: 10px; */
  margin: 20px auto 0;
  box-shadow: 0 0 5px #000;
}

.bigItalic {
  background-color: #0005;
  font-style: italic;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #fff;
  margin: 250px 0 0 0;
  text-shadow: 1px 1px 2px #000;
  padding: 10px;
}

.italicPhoto {
  display: none;
}

.homeText {
  margin: 40px 40px 0 40px;
  text-align: justify;
  font-size: 1rem;
  line-height: 1.8rem;
}

.homeText p.lastParagraph {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.homeTextFaq {
  margin: 40px 40px 0 40px;
  text-align: justify;
  font-size: 1rem;
  line-height: 1.8rem;
}

.homeTextFaq h3 {
  font-size: 1.05rem;
  font-family: montserratBold;
  color: #333;
  margin-top: 50px;
}

.homeTextFaq p.lastParagraph {
  margin-bottom: 0;
  padding-bottom: 10px;
}

.fullWidth {
  display: block;
  width: 80%;

  background-color: #fff;
  text-align: center;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.7rem;
  color: var(--corn);

  padding: 80px 10px;
  margin: 0 auto;
}

.areas h3,
.areas2 h3 {
  text-align: center;
  margin: 0 0 20px 0;
}
.areas,
.areas2 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
  margin-bottom: 100px;
}

.flexSupport {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

ul.flexSupport li {
  width: 100%;
  display: block;
  margin: auto;
  padding: 0 5px;
  background-color: #ddaa0040;
  font-weight: 600;
  color: #333333;
}

.flexSupport li:nth-child(2n) {
  background-color: #ddaa0060;
}

ul.flexSupport li.listEnd {
  font-style: italic;

  text-align: center;
  padding: 5px;
  margin: 0;
}

/* ***************************************************************************************88 */

@media (min-width: 700px) {
  .italicPhoto {
    display: block;
    width: 35%;
    float: left;
    margin: 10px 40px 20px 10px;
    box-shadow: 0 0 10px #444;
  }

  .italicWrapper {
    border: none;
    background-image: none;
    box-shadow: none;
    max-width: none;
  }

  .bigItalic {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #ddaa0080;
    box-shadow: 0 0 55px #fff inset;
  }

  .fullWidth {
    font-size: 1.4rem;
  }

  .areas {
    flex-direction: row;

    margin: 100px auto 100px;
    /* box-shadow: 0 0 10px #444; */
  }

  .areas2 {
    flex-direction: row-reverse;

    margin: 100px auto 200px;
    /* box-shadow: 0 0 10px #444; */
  }

  .areasPicture {
    background-image: url(../assets/notes.jpg);
    background-position: top;
    background-size: cover;
    max-width: 300px;
  }

  .areasPicture2 {
    background-image: url(../assets/desk.jpg);
    background-position: top;
    background-size: cover;
    max-width: 300px;
  }

  .areasPictureSubLayer {
    background-color: #00000080;
    width: 100%;
    height: 100%;
  }
  .areasPicture h3 {
    font-size: 2.5rem;
    padding: 100px 20px 0;
    line-height: 5rem;
    font-family: montserrat;
    /* text-transform: uppercase; */
    color: #fff;
    letter-spacing: 0rem;
  }

  .areasPicture2 h3 {
    font-size: 2.5rem;
    padding: 50px 20px 0;
    line-height: 5rem;
    font-family: montserrat;
    color: #fff;

    letter-spacing: 0rem;
  }

  ul.flexSupport {
    margin: 0;
  }

  ul.flexSupport li {
    padding-left: 30px;
  }

  /* h3.benefitsHeader {
    font-size: 2rem;
  } */
}

/* for privacy policy address list */

ul.noDecoration {
  list-style: none;
  padding-left: 0;
}

/* for quote at bottom of ABOUT ME page */

.fullWidthQuote {
  display: block;
  width: 100%;
  background-color: #fff;
  text-align: center;
  font-style: italic;
  font-size: 1.4rem;
  line-height: 1rem;
  color: var(--corn);

  padding: 10px;
  margin: 0;
}

.henryFord {
  text-align: center;

  padding-bottom: 30px;
  font-size: 00.8rem;
  margin: 0;
}

#bookingButton {
  display: block;
  margin: 100px auto;
  font-size: 1.5rem;
  padding: 5px 10px;
  width: 200px;
  text-align: center;
  background-color: var(--corn);
  color: white;
  border-radius: 20px;
}

#bookingButton:hover {
  opacity: 0.8;
}

.bookingLink {
  text-decoration: none;
}

.contactLink {
  display: block;
  margin: 0 auto 100px;
  background-color: #fff;

  color: var(--corn);
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 1.1rem;
  padding: 20px;
  /* box-shadow: 0 0 10px #555; */
  /* border-radius: 10px; */
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}

.contactLinkButton {
  display: block;
  margin: 0 auto;
  background-color: var(--corn);
  color: #fff;
  border-radius: 20px;
  max-width: 200px;
  padding: 10px 0;
  font-family: montserrat;
  font-style: normal;
}

.contactLinkButton:hover {
  opacity: 0.8;
}

.contactLink a {
  text-decoration: none;
}

.contact-cta {
  margin-top: 100px;
  text-align: center;
  font-size: 1.3rem;
}

.iframeWrapper {
  overflow: hidden;
  position: relative;
  width: 95%;
  margin: 80px auto 0;
}

.iframeWrapper::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.iframeWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 700px) {
  .iframeWrapper {
    width: 60%;
  }
}
/* ////////////////////////////////////////////////////CANVA STYLING//////////////////////////////////// */

.canva-outer-classes {
  width: 90%;
  margin: 0 auto;
}

.canva-outer-classes > .canva-wrapper {
  width: 67.3%;
  margin: auto;
}

.classes-container {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 900px) {
  .classes-container {
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .canva-outer-classes {
    width: 50%;
    max-width: 400px;
  }

  .canva-outer-classes > .canva-wrapper-classes {
    margin: 10px;
  }

  .classes-container > .homeText {
    width: 45%;
  }
}

.canva-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 50px auto;
}

.canva-outer {
  width: 80%;
  margin: 80px auto;
}

.canva-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 141.5%;
  padding-bottom: 0;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
}

.canva-wrapper-classes {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 141.5%;
  padding-bottom: 0;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
}

.canva-wrapper > iframe,
.canva-wrapper-classes > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 900px) {
  .canva-outer {
    width: 30%;
  }
}

.sessions-bottom-text {
  width: 60%;
  margin: auto;
  text-align: justify;
  font-size: 1.1rem;
}

.session-quote {
  width: 100%;
  text-align: center;
  font-family: montserratBold;
}

.blogItem {
  width: 80%;
  margin: 80px auto;

  font-size: 1rem;
  line-height: 1.8rem;
}
.blogItem time {
  text-align: left;
}
.blogItem h3 {
  font-size: 1.6rem;
}

.blogItem a {
  text-decoration: none;
}

.blogItem img {
  width: 100%;
  /* float: right; */

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.blogText {
  text-align: justify;

  margin: auto;
}

@media (min-width: 700px) {
  .blogItem img {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 55%;
  }
}
