:root {
  --lazerLemon: #ffff6d60;
  --corn: #ddaa00;
}

@font-face {
  font-family: montserrat;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: montserratBold;
  src: url(../fonts/Montserrat-Bold.ttf);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "montserrat";
}

div.outerGutters {
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1 {
  visibility: hidden;
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h2 {
  background-color: #ddaa00;
  color: #fff;
  /* text-align: center; */
  margin-top: 40px;
  padding-left: 40px;
  font-family: montserrat;
  text-transform: uppercase;
}

h3 {
  font-family: montserrat;
  font-size: 0.9rem;
  text-align: left;
  margin: 30px 0 10px;
}

a {
  color: #000;
}
div.header {
  background-color: #fff;
  display: flex;

  align-items: center;
  justify-content: flex-end;
}

nav.topNav {
  display: none;
}

p.menuIcon {
  display: block;
  height: 32px;
  padding: 5px;
  color: var(--corn);
  margin: 0;
  letter-spacing: -1px;
  font-weight: bold;
  font-size: 10pt;
}

nav.dropDown {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 80vh;
  z-index: 2;
}

nav.dropDown .link {
  display: block;
  margin: 0 auto;
  text-align: center;
  color: var(--corn);
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px;
}

img.topLogo {
  width: 250px;
  display: block;
  margin: auto;
}

div.headerUnderline {
  width: 100%;
  height: 2px;
  background-color: var(--corn);
}

p.copyright {
  text-align: center;
}

ul.priceList {
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 50px auto 50px;
  width: 90%;
  padding: 0;
  font-size: 0.7rem;
  /* border: 2px solid #000; */
  line-height: 1.5rem;
}

.priceList li {
  /* border: 1px solid #000; */
  text-align: center;
  margin: 0;
  padding: 10px;
  background-color: #ddaa0060;
  align-self: center;
}

.priceList li:nth-child(2n) {
  background-color: #ddaa0040;
}

.pageBody {
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  padding-bottom: 100px;
}

.pageBodyHome {
  display: block;
  width: 100%;
  margin: 0 auto;
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  /* padding-bottom: 50px; */
}

.noJustify {
  text-align: left;
}

img.lAlign {
  width: 80%;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 5px #666;
}

img.rAlign {
  width: 80%;
  display: block;
  margin: 0 auto;
  box-shadow: 0 0 5px #666;
}

@media (min-width: 700px) {
  img.lAlign {
    width: 300px;
    float: left;
    margin: 10px;
    box-shadow: 0 0 5px #666;
  }

  img.rAlign {
    width: 300px;
    float: right;
    margin: 10px;
    box-shadow: 0 0 5px #666;
  }
}

form.contactForm {
  display: grid;
  grid-template-columns: 1fr;
  margin: 50px auto;
  width: 70%;
}

.contactFormButton {
  margin: 50px auto;
  background-color: var(--corn);
  color: #fff;
  border: none;
  padding: 5px 0;
  border-radius: 20px;
  width: 40%;
  max-width: 300px;
}

@media (min-width: 700px) {
  form.contactForm {
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }

  .contactFormButton {
    grid-column: 1 / span 2;
  }
}

.contactFormButton:hover {
  background-color: #dcb73f;
}

.formComplete {
  text-align: center;
  font-size: 1.2rem;
  margin: 100px 10px 200px;
}

h3.contactName {
  margin: 5px 0;
}

.socialLinks {
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.socialLinks img {
  height: 30px;
  margin: 25px;
}

.price-container {
  margin: 100px auto 30px;
  font-size: 1rem;
}

@media (min-width: 800px) {
  .price-container {
    font-size: 1.2rem;
    width: 60%;
  }
}
.price-container td {
  padding: 20px 0;
  /* max-width: 150px; */
  /* text-align: justify; */
}

.price-container .price {
  text-align: center;
}
